// Core
import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 375px;

  font-style: normal;
  font-weight: normal;
`;
