import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import types from './types';

const initialState = {
  status: undefined,
  error: undefined,
};

export default function contacts(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case `${types.MAIL_SEND}_${PENDING}`:
      return {
        ...state,
        status: 'sending',
      };
    case `${types.MAIL_SEND}_${FULFILLED}`:
      return {
        ...state,
        status: payload.status === 201 ? 'success' : 'error',
      };
    case `${types.MAIL_SEND}_${REJECTED}`:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    case `${types.RESET_STATUS}`:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
