import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import Routes from './routes';

// Styles
import { Wrapper } from './styles';

// Google analytics tracker
import ReactGA from '../googleAnalytics';

class App extends Component {
  componentDidMount() {
    // track page visit to goggle analytics
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>AgileNix - Mobile & Web Application Development</title>
        </Helmet>
        <Wrapper>
          <Routes />
        </Wrapper>
      </div>
    );
  }
}

export default withRouter(App);
