import ReactGA from 'react-ga';

const trackingId = process.env.REACT_APP_TRACKING_ID;

/**
 * Initializes google analytics tracker here
 */
ReactGA.initialize(trackingId, {
  debug: process.env.NODE_ENV !== 'production',
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

export default ReactGA;
