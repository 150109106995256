import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { offsetReducer } from '../../src/app/bus/sectionOffsets/reducer';
import contacts from '../../src/app/bus/contacts/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    sectionOffsets: offsetReducer,
    contacts,
  });
