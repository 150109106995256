// Types
import types from './types';

const initialState = {};

export const offsetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_OFFSET:
      return {
        ...state,
        [payload.id]: payload,
      };

    default:
      return state;
  }
};
